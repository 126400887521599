import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import { Link } from "gatsby"
import Head from "../components/head"
import stars from "../images/5stars.png"

// const liStyle = {
//   display: "list-item",
//   listStyleType: "disc",
//   paddingBottom: ".5rem",
//   marginLeft: "2rem",
//   fontSize: "14px",
//   color: "#777575",
// }
const Nicewords = () => {
  return (
    <Layout>
      <Head
        title="Testimonials"
        description="Some of the nice words thrown our way by our customers and clients."
      />

      <Container>
        {/* <!-- breadcrumb start --> */}
        <div className="breadcrumb-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="page-title">
                  <h2>Nice Words Thrown Our Way</h2>
                </div>
              </div>
              <div className="col-sm-6">
                <nav aria-label="breadcrumb" className="theme-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Nice Words
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- breadcrumb End --> */}

        {/* <!-- section start --> */}
        <section className="section-b-space blog-detail-page review-page">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                {/* <h3>NICE WORDS THROWN OUR WAY...</h3> */}
                <ul className="comment-section">
                  <h4>
                    Verified Comments From Our Customers (People also have nice
                    things to say about our{" "}
                    <Link to="/promotional">promotional products!</Link>)
                  </h4>
                  <p></p>
                  {/* <ul className="comment-section"> */}
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>
                          CJarvis
                          {/* <span>( 12 Jannuary 2018 at 1:30AM )</span> */}
                        </h6>
                        <p>
                          I ordered a Lester Dummy on Friday and I received it
                          on Monday. EXCELLENT AND FAST SERVICE!!!!!!!!!!!!!!.
                          Love this web site. AWESOME!!!!!!. I will definitely
                          order again.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>McGeckoCrane </h6>
                        <p>
                          Extremely fast shipping, only took three days to get
                          after placing my order! Great job!
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Elliott</h6>
                        <p>
                          I love this 100%, I bought all of my ventriloquism
                          products from you and you have never failed me yet.
                          Very good. I love it.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Heidi</h6>
                        <p>
                          Thank You so much for your product. Your awesome
                          customer service deserves praise. My son had a
                          birthday and didn't think we were going to get him the
                          "SLAPPY" doll from the Goosebumps series because NO
                          store carried such a thing. Once surfing the internet
                          I was led to ThrowThings.com and let me tell you it
                          was the best birthday surprise ever. Your prices were
                          great and the shipping was on time. Once again Thank
                          You so much.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>LC</h6>
                        <p>
                          Service was extremely fast, and the product I
                          purchased (a marionette) was of superb quality at a
                          very reasonable price. I am pleased with my purchase,
                          and in trusting this company. I would not hesitate to
                          order from them again in the future.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Princeton</h6>
                        <p>
                          I just got my Charlie McCarthy and it seriously felt
                          like Christmas! Just making the eyes move so smoothly
                          and easily totally made the upgrade SO worth it. I am
                          so surprised at the quality and the durability of the
                          dummy I'm actually writing an online review. It's THAT
                          good. Thanks!
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Lisa</h6>
                        <p>
                          I ordered 3 basic dummies for my son's birthday and
                          was expecting them to arrive in a few weeks. I was so
                          delighted that they came the next morning. WOW. That's
                          service. Thank you to all the people from
                          ThrowThings.com
                        </p>
                        <p>
                          (Of course not all deliveries are made this quickly,
                          but we do make every effort to get orders shipped
                          FAST!)
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Steve</h6>
                        <p>
                          I placed my third order with your company because of
                          your fine service, and above all the sense of humor
                          and laughter you give me on a daily basis every time I
                          visit your Website. Yes, you do have a lot to be proud
                          of. If I were to create a Website, it would be
                          impossible for me to match yours. Your customer
                          service could not be better. The young lady answering
                          your phone is not only intelligent, but very nice to
                          speak to. Even (website removed), which I consider to
                          be one of the top Websites on the Internet, is in no
                          way better than your customer service department, and
                          attention you give to each of your customers. I have
                          never written to any other Website...however, you have
                          given me so much enjoyment and pleasure with your
                          products, this is one note I had to write.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Lena</h6>
                        <p>
                          Ordered Standard upgrade Mortimer Snerd on Wednesday,
                          received him on Friday using the standard shipping
                          method. He was very well packaged in box stuffed with
                          peanuts and double wrapped and taped in a bag. Product
                          is as represented on site. Would do business with this
                          company again! Really fast service!
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Joe</h6>
                        <p>
                          These guys really came through for me. I had a
                          horrible time with Amazon after they canceled my two
                          day shipping and was at the point where the order (a
                          gift) was not going to make it and I would have to
                          cancel my order. After contacting ThrowThings.com, I
                          was happily surprised to find that my order had
                          already shipped and would easily make my son's birth
                          date (a huge worry off my mind). Thanks again for
                          turning a very unpleasant situation into a happy day
                          for my son!
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Leslie</h6>
                        <p>
                          I had never ordered anything from ThrowThings.com so I
                          decided to use US mail and send a money order. I
                          mailed it on a Monday and got the item the following
                          Monday and had an e-mail confirmation when they
                          received the order. Excellent!!!
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Anne</h6>
                        <p>
                          Great speedy delivery. Dummy much nicer than expected.
                          Very very pleased with transaction.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>eastbville</h6>
                        <p>
                          A first-class buying experience. Shipment was received
                          in factory mint condition in a fast 3 day period of
                          time w/the S&H being very reasonable. The
                          Ventriloquist Doll was brand new, nice quality and
                          exactly as advertised. Couldn't ask for any better
                          than this Seller. The 10 yr. old girl, I gifted to,
                          was speechless.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>WRobbins</h6>
                        <p>
                          Excellent shipping time, product was boxed very well
                          for shipping and arrived without any damage. The
                          marionette I ordered was beyond my expectations and I
                          would order again from ThrowThings. Thank you for your
                          professional completion of my order.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>SS</h6>
                        <p>
                          Very fast, ex. Service everything as stated (great)
                          very well made my little guy going to love to come
                          Xmas . A+++++++ I can't stop fooling around with it.
                          May have to get my own don't think he's gonna share
                          after Xmas lol
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="media">
                      <img src={stars} alt="5 Stars" />
                      <div className="media-body">
                        <h6>Kolleen</h6>
                        <p>
                          I received "Lindsay" the deluxe upgraded dummy and WOW
                          she was shipped SUPER fast, and much more BEAUTIFUL in
                          person!!! This is a class act place to order from, I
                          will definitely spread the word and will be back to
                          order more!!
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section ends --> */}
      </Container>
    </Layout>
  )
}

export default Nicewords
